.crylio-header {
    height: 7.5rem !important;
    background-color: #212C4A !important;
}

.crylio-header__toggle {
    border: 0;
    padding: 0;
    border: 0;
    background: transparent;
    color: #EEE40A;
    font-size: 3rem;
}

.crylio-header__logo {
    padding: 1.1rem 1rem 1rem 1rem !important;
}