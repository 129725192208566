@import url("https://cdn.jsdelivr.net/npm/halfmoon@1.1.0/css/halfmoon-variables.min.css");
@import url("./hm-overrides.css");

.crylio-content-wrapper {
    top: 7.5rem !important;
    height: calc(100% - 7.5rem) !important;
    padding: 2.5rem;
}

.crylio-content-wrapper h1 {
    margin-top: 0 !important;
    margin-bottom: 3.2rem;
    line-height: 1 !important;
}

@media only screen and (min-width: 769px) {
    .crylio-content-wrapper {
        left: 26rem !important;
        width: calc(100% - 26rem) !important;
    }
}