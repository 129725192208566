@import url(https://cdn.jsdelivr.net/npm/halfmoon@1.1.0/css/halfmoon-variables.min.css);
.crylio-header {
    height: 7.5rem !important;
    background-color: #212C4A !important;
}

.crylio-header__toggle {
    border: 0;
    padding: 0;
    border: 0;
    background: transparent;
    color: #EEE40A;
    font-size: 3rem;
}

.crylio-header__logo {
    padding: 1.1rem 1rem 1rem 1rem !important;
}
.crylio-sidebar {
    top: 7.5rem !important;
    height: calc(100% - 7.5rem) !important;
}
.cpr-expanded-panel {
    height: auto;
    max-height: 0;
    transition: max-height 0.5s ease;
}

.cpr-expanded-panel.open {
    max-height: 175px;
}
.spr-expanded-panel {
    height: auto;
    max-height: 0;
    transition: max-height 0.5s ease;
}

.spr-expanded-panel.open {
    max-height: 175px;
}
:root {
    --dark-color: #37415c;
    --dark-color-light: #4d566e;
    --dark-color-dark: #212c4a;

    --gray-color-very-light: #f7f7f7;
    --gray-color-light: #e9eaed;
    --gray-color: #d3d5db;
    --gray-color-dark: #a6abb7;
    --gray-color-very-dark: #4d566e;

    --dm-base-text-color: rgba(255, 255, 255, 1);
    --dm-base-text-color-light: rgba(255, 255, 255, 0.8);
}

.bg-gray {
    background-color: #d3d5db;
    background-color: var(--gray-color);
}

.bg-gray-light-lm {
    background-color: #e9eaed;
    background-color: var(--gray-color-light);
}

.bg-gray-lm {
    background-color: #d3d5db;
    background-color: var(--gray-color);
}

.bg-gray-dark-lm {
    background-color: #a6abb7;
    background-color: var(--gray-color-dark);
}

.dark-mode .bg-dark-light-dm {
    background-color: #4d566e;
    background-color: var(--dark-color-light);
}


.pl-35 {
    padding-left: 3.5rem!important;
}


.w-10 {
    width: 1rem!important;
}

.h-10 {
    height: 1rem!important;
}

.w-18 {
    width: 1.8rem!important;
}

.h-18 {
    height: 1.8rem!important;
}

.w-20 {
    width: 2rem!important;
}

.h-20 {
    height: 2rem!important;
}

.w-30 {
    width: 3rem!important;
}

.h-30 {
    height: 3rem!important;
}

.w-40 {
    width: 4rem!important;
}

.h-40 {
    height: 4rem!important;
}


.font-size-10 {
    font-size: 1rem!important;
}


.opacity-60 {
    opacity: 0.6;
}

.hover-opacity-100:hover {
    opacity: 1;
}


.sidebar-overlay {
    z-index: 39 !important;
}
.crylio-content-wrapper {
    top: 7.5rem !important;
    height: calc(100% - 7.5rem) !important;
    padding: 2.5rem;
}

.crylio-content-wrapper h1 {
    margin-top: 0 !important;
    margin-bottom: 3.2rem;
    line-height: 1 !important;
}

@media only screen and (min-width: 769px) {
    .crylio-content-wrapper {
        left: 26rem !important;
        width: calc(100% - 26rem) !important;
    }
}
