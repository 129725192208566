:root {
    --dark-color: #37415c;
    --dark-color-light: #4d566e;
    --dark-color-dark: #212c4a;

    --gray-color-very-light: #f7f7f7;
    --gray-color-light: #e9eaed;
    --gray-color: #d3d5db;
    --gray-color-dark: #a6abb7;
    --gray-color-very-dark: #4d566e;

    --dm-base-text-color: rgba(255, 255, 255, 1);
    --dm-base-text-color-light: rgba(255, 255, 255, 0.8);
}

.bg-gray {
    background-color: var(--gray-color);
}

.bg-gray-light-lm {
    background-color: var(--gray-color-light);
}

.bg-gray-lm {
    background-color: var(--gray-color);
}

.bg-gray-dark-lm {
    background-color: var(--gray-color-dark);
}

.dark-mode .bg-dark-light-dm {
    background-color: var(--dark-color-light);
}


.pl-35 {
    padding-left: 3.5rem!important;
}


.w-10 {
    width: 1rem!important;
}

.h-10 {
    height: 1rem!important;
}

.w-18 {
    width: 1.8rem!important;
}

.h-18 {
    height: 1.8rem!important;
}

.w-20 {
    width: 2rem!important;
}

.h-20 {
    height: 2rem!important;
}

.w-30 {
    width: 3rem!important;
}

.h-30 {
    height: 3rem!important;
}

.w-40 {
    width: 4rem!important;
}

.h-40 {
    height: 4rem!important;
}


.font-size-10 {
    font-size: 1rem!important;
}


.opacity-60 {
    opacity: 0.6;
}

.hover-opacity-100:hover {
    opacity: 1;
}


.sidebar-overlay {
    z-index: 39 !important;
}